
import React, { useState, useEffect } from 'react';

import Graphic404 from "../images/404_graphic.svg"

export default function Page404() {

    const [timeLeft, setTimeLeft] = useState(10)

    useEffect(() => {
        if (timeLeft === 0) {
            if (typeof window !== 'undefined') {
                window.location = '/';
            }
            return;
        }

        const currentInterval = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(currentInterval);
    }, [timeLeft]);

    return (
        <div className='page-inner-wrapper page-wrapper-404'>
            <div className='content-wrapper-404'>
                <img src={Graphic404} className='404-img' alt="404" />
                <h1 className='header-404'>Are you lost?</h1>
                <h4 className='subtitle-404'>The page you are trying to reach doesn't exist, at least not yet ;). You will automatically be sent back to the homepage in 10 seconds.</h4>
            </div>


        </div>
    )
}


